<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                <img src="@/assets/homePage/关于盛泰banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 中间内容区域 -->
        <div class="content">
            <div class="content_nav">
                <p v-for="item in makeList1" class="p_1" :class="{ bottomBorder: item.id == id }" :key="item.id"
                    @click="mackBtn(item.id)">{{ item.name }}</p>
            </div>
            <img style="width: 100%;" v-if="id == 2" src="@/assets/about/manufacture/制造设备/顶部图.png" alt="">
            <img style="width: 100%;" v-if="id == 1" src="@/assets/about/manufacture/制造环境/顶部修饰图.png" alt="">
            <div class="content_bottom" v-for="item in dataList" :key="item.id">
                <div class="content_bottom_left">
                    <div class="text">
                        {{ item.name_left }}
                    </div>
                    <img :src="item.img_right" alt="">
                </div>
                <div class="content_bottom_right">
                    <img :src="item.img_left" alt="" />
                    <div class="text">
                        {{ item.name_right }}
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                <img src="@/assets/homePage/关于盛泰banner.png" class="images">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容区域 -->
        <div class="content">
            <div class="content_nav">
                <p v-for="item in makeList1" class="p_1" :class="{ bottomBorder: item.id == id }" :key="item.id"
                    @click="mackBtn(item.id)">{{ item.name }}</p>
            </div>
                        <img style="width: 100%;" v-if="id == 2" src="@/assets/about/manufacture/制造设备/顶部图.png" alt="">
            <img style="width: 100%;" v-if="id == 1" src="@/assets/about/manufacture/制造环境/顶部修饰图.png" alt="">
            <div class="content_bottom" v-for="item in dataList" :key="item.id">
                <div class="content_bottom_left">
                    <div class="text">
                        {{ item.name_left }}
                    </div>
                    <img :src="item.img_right" alt="">
                </div>
                <div class="content_bottom_right">
                    <img :src="item.img_left" alt="" />
                    <div class="text">
                        {{ item.name_right }}
                    </div>
                </div>
            </div>

        </div>
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    components: { foot, headers, move_header, move_foot },

    setup() {
        const router = useRouter();
        const state = reactive({
            makeList1: [
                { id: 1, name: '制造环境' },
                { id: 2, name: '制造设备' }
            ],
            id: 1,
            dataList: [],
            // 制造设备
            makeList: [
                { id: 1, name_left: '自动上料+焊机+自动下料设备 ···', name_right: '······ 4工位设备', img_left: require('@/assets/about/manufacture/制造设备/自动上料+焊机+自动下料设备.png'), img_right: require('@/assets/about/manufacture/制造设备/4工位设备.png') },
                { id: 2, name_left: '自动激光镭码机 ······', name_right: '······ X-Ray设备', img_left: require('@/assets/about/manufacture/制造设备/自动激光镭码机.png'), img_right: require('@/assets/about/manufacture/制造设备/X-Ray设备.png') },
                { id: 3, name_left: 'NB_Flip Chip TDK设备 ·····', name_right: '······ NB线AA站设备', img_left: require('@/assets/about/manufacture/制造设备/NB_Flip Chip TDK设备.png'), img_right: require('@/assets/about/manufacture/制造设备/NB线AA站设备.png') },
                { id: 4, name_left: 'NB线 FlipChip： Wafer清洗+SSB植球机 ···', name_right: '····· NB_FC线：Underfill点胶设备', img_left: require('@/assets/about/manufacture/制造设备/NB线 FlipChip： Wafer清洗+SSB植球机.png'), img_right: require('@/assets/about/manufacture/制造设备/NB_FC线：Underfill点胶设备.png') },
                { id: 5, name_left: 'COM类集上下料+ 贴Holder镜头+烘烤一体 ······', name_right: '······ 自动摆盘机', img_left: require('@/assets/about/manufacture/制造设备/COM.png'), img_right: require('@/assets/about/manufacture/制造设备/自动摆盘机.png') },
                { id: 6, name_left: 'NB_CSP LHA站设备 ······', name_right: '······ 等离子清洗机', img_right: require('@/assets/about/manufacture/制造设备/等离子清洗机.png'), img_left: require('@/assets/about/manufacture/制造设备/NB.png') },
            ],
            // 制造环境
            manufacturing: [
                { id: 1, name_left: '高速打件机 ······', name_right: '······ COB大连线', img_right: require('@/assets/about/manufacture/制造环境/高速打件机.jpg'), img_left: require('@/assets/about/manufacture/制造环境/COB大连线.jpg') },
                { id: 2, name_left: '自动贴片站 ······', name_right: '······ DA连快速烤箱连收板机', img_right: require('@/assets/about/manufacture/制造环境/DA连快速烤箱连收板机.jpg'), img_left: require('@/assets/about/manufacture/制造环境/自动贴片站.jpg') },
                { id: 3, name_left: 'AFC测试设备 ······', name_right: '······ 从DA前等离子清洗连到LA3大连线', img_right: require('@/assets/about/manufacture/制造环境/AFC测试设备.jpg'), img_left: require('@/assets/about/manufacture/制造环境/从DA前等离子清洗连到LA3大连线.jpg') },
                { id: 4, name_left: 'NB线 FlipChip：Wafer清洗+SSB植球机 ······', name_right: '······ 自动点胶机', img_right: require('@/assets/about/manufacture/制造环境/NB线 FlipChip：Wafer清洗+SSB植球机.jpg'), img_left: require('@/assets/about/manufacture/制造环境/自动点胶机.jpg') },
                { id: 6, name_left: 'COM类：集上下料+贴Holder镜头+烘烤一体 ······', name_right: '······ SMT车间整条NB线', img_right: require('@/assets/about/manufacture/制造环境/COM类：集上下料+贴Holder镜头+烘烤一体.jpg'), img_left: require('@/assets/about/manufacture/制造环境/SMT车间整条NB线.jpg') },
                { id: 5, name_left: '自动焊接机 ······', name_right: '······ 四工位线体', img_right: require('@/assets/about/manufacture/制造环境/自动焊接机.jpg'), img_left: require('@/assets/about/manufacture/制造环境/4工位线体.jpg') },

            ]
        });
        let methods = {
            mackBtn(id) {
                state.id = id
                if (id == 1) {
                    state.dataList = state.manufacturing
                } else if (id == 2) {
                    state.dataList = state.makeList
                }
            }
        }
        onMounted(() => {
            state.dataList = state.manufacturing
        });
        return {
            ...toRefs(state), ...methods
        };
    },
};
</script>

<style scoped lang="less">
.box {
    width: 100%;

    .bottomBorder {
        border-bottom: 3px solid red;
    }

    .content {
        margin: 0 auto;
        max-width: 1920px;
        width: 70%;

        .content_nav {
            text-align: right;

            .p_1 {
                padding-bottom: 5px;
                cursor: pointer;
                display: inline-block;
                margin-right: 20px;
                font-size: 1.5rem;
            }
        }

        .content_bottom {
            display: flex;
            width: 90%;
            margin: 50px auto;

            .content_bottom_left {
                flex: 2;

                .text {
                    width: 105%;
                    height: 120px;
                    background-color: #3163D0;
                    text-align: center;
                    line-height: 120px;
                    font-size: 20px;
                    color: #fff;
                }

                img {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 20px 20px 0 0;
                    z-index: 9;
                }
            }

            .content_bottom_right {
                flex: 3;

                img {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 0 11px 20px;
                    z-index: 9;
                }

                .text {
                    width: 103%;
                    color: #fff;
                    font-size: 20px;
                    height: 130px;
                    background-color: #2BB0CD;
                    text-align: center;
                    line-height: 130px;
                    margin-left: -20px;

                }
            }
        }
    }
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;

        .bottomBorder {
            border-bottom: 8px solid red;
        }

        .content {
            padding: 30px;

            .content_nav {
                text-align: center;

                .p_1 {
                    padding-bottom: 5px;
                    cursor: pointer;
                    display: inline-block;
                    margin-right: 50px;
                    font-size: 60px;
                }

            }
                 .content_bottom {
            display: flex;
            width: 100%;
            margin: 50px auto;

            .content_bottom_left {
                flex: 2;

                .text {
                    width: 105%;
                    height: 120px;
                    background-color: #3163D0;
                    text-align: center;
                    line-height: 120px;
                    font-size: 50px;
                    color: #fff;
                }

                img {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 20px 20px 0 0;
                    z-index: 9;
                }
            }

            .content_bottom_right {
                flex: 3;

                img {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 0 14px 20px;
                    z-index: 9;
                }

                .text {
                    width: 103%;
                    color: #fff;
                    font-size: 50px;
                    height: 130px;
                    background-color: #2BB0CD;
                    text-align: center;
                    line-height: 130px;
                    margin-left: -20px;

                }
            }
                 }
        }
    }
}
</style>